<template>
  <v-container>
    <v-row>
      <v-col>
        <v-btn color="secondary" @click="downloadProducts" :loading="loadingDownloadProducts"><span v-html="$t('processing_download_products')" /></v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-btn color="secondary" @click="downloadProductGroups" :loading="loadingDownloadProductGroups"
          ><span v-html="$t('processing_download_productgroups')"
        /></v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-btn color="secondary" @click="downloadRates" :loading="loadingDownloadRates"><span v-html="$t('processing_download_invoice_rates')" /></v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-btn color="secondary" @click="printMaxi" :loading="loadingPrintMaxi"><span v-html="$t('processing_download_maxi_form')" /></v-btn>
      </v-col>
    </v-row>
    <v-row v-if="$privileges.has({ path: '/sapLogs', permission: 'findSapLogEntries' })">
      <v-col>
        <v-btn color="secondary" @click="printSapLogEntries" :loading="loadingPrintSapLogEntries"
          ><span v-html="$t('processing_download_last_fourteen_days')"
        /></v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>
<script lang="ts">
import { fileCreator, showError } from '@/services/axios'
import { apiURL } from '@/main'
import * as XLSX from 'xlsx/xlsx.mjs'

import { defineComponent } from 'vue'
export default defineComponent({
  name: 'ProductReports',
  data() {
    return {
      loadingDownloadProducts: false,
      loadingDownloadProductGroups: false,
      loadingDownloadRates: false,
      loadingPrintMaxi: false,
      loadingPrintSapLogEntries: false
    }
  },
  computed: {},
  methods: {
    async downloadProducts() {
      this.loadingDownloadProducts = true
      try {
        const result = await this.axios.get(apiURL + '/products/detailedProductList', {
          headers: { Accept: 'application/msexcel' },
          responseType: 'blob'
        })
        fileCreator(await result, 'detailedProductList.xlsx')
      } catch (e) {
        let responseObj = await e.response.data.text()
        showError({ response: { data: JSON.parse(responseObj) } })
      } finally {
        this.loadingDownloadProducts = false
      }
    },
    async downloadProductGroups() {
      this.loadingDownloadProductGroups = true
      let productGroups = []
      try {
        const result = await this.axios.get(apiURL + '/productGroups/findAll?term', {
          headers: { Accept: 'application/json' },
          params: { size: 1000 }
        })
        if (await result) {
          productGroups = result.data.content
          let workbook = XLSX.utils.book_new()
          let worksheet_name = 'SheetJS'
          let worksheet = XLSX.utils.json_to_sheet(productGroups)
          XLSX.utils.book_append_sheet(workbook, worksheet, worksheet_name)
          XLSX.writeFile(workbook, 'productGroups.xlsx')
        }
      } catch (e) {
        showError(e)
      } finally {
        this.loadingDownloadProductGroups = false
      }
    },
    async downloadRates() {
      this.loadingDownloadRates = true
      try {
        const result = await this.axios.get(apiURL + '/products/rates', {
          headers: { Accept: 'application/msexcel' },
          responseType: 'blob'
        })
        fileCreator(await result, 'rates.xlsx')
      } catch (e) {
        let responseObj = await e.response.data.text()
        showError({ response: { data: JSON.parse(responseObj) } })
      } finally {
        this.loadingDownloadRates = false
      }
    },
    async printMaxi() {
      this.loadingPrintMaxi = true
      try {
        const result = await this.axios.get(apiURL + '/processingFormTemplates/1/reportTSM1', {
          headers: { Accept: 'application/pdf' },
          responseType: 'blob'
        })
        fileCreator(await result, 'maxi.pdf')
      } catch (e) {
        let responseObj = await e.response.data.text()
        showError({ response: { data: JSON.parse(responseObj) } })
      } finally {
        this.loadingPrintMaxi = false
      }
    },
    async printSapLogEntries() {
      this.loadingPrintSapLogEntries = true
      try {
        const result = await this.axios.get(apiURL + '/sapLogs/findSapLogEntries', {
          headers: { Accept: 'application/msexcel' },
          responseType: 'blob'
        })
        fileCreator(result, 'SapLogEntries.xlsx')
      } catch (e) {
        let responseObj = e.response.data.text()
        showError({ response: { data: JSON.parse(responseObj) } })
      } finally {
        this.loadingPrintSapLogEntries = false
      }
    }
  },
  components: {}
})
</script>
